import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: true,
    infinite: true,
    slidesToShow: (window.innerWidth <= 760) ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear"
};

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }




    componentDidMount() {
        const cacheKey = "tabBannerListCache";
        const cacheTTL = 3 * 60 * 60 * 1000; // 3 ชั่วโมงในมิลลิวินาที
        const cachedData = JSON.parse(localStorage.getItem(cacheKey));
    
        // ตรวจสอบแคช
        if (cachedData && (Date.now() - cachedData.timestamp < cacheTTL)) {
            console.log("Using cached data for tabbannerlist");
            this.setState({ tabbannerlist: cachedData.data });
        } else {
            // ถ้าไม่มีแคชหรือแคชหมดอายุ ให้เรียก API
            instance.get("/api/v1/tabbannerlist?System=" + Systems)
                .then((res) => {
                    console.log("tabbannerlist", res.data);
                    if (res.data.status === 200) {
                        this.setState({ tabbannerlist: res.data.message });
    
                        // บันทึกข้อมูลลงในแคช
                        localStorage.setItem(cacheKey, JSON.stringify({
                            timestamp: Date.now(),
                            data: res.data.message,
                        }));
                    }
                }).catch((err) => {
                    console.error("Error fetching tabbannerlist:", err);
                });
        }
    }

    render() {
        return (
            <div>
                <div
                    className="flicking-viewport"
                    style={{
                        userSelect: "none",
                        WebkitUserDrag: "none",
                        touchAction: "pan-y"
                    }}
                >
                    <Slider {...settings}>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-2}
                            aria-hidden="true"
                            tabIndex={-1}

                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-1.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}

                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-2.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-3.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-4.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-5.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            className="-slide-inner-wrapper  -slick-item slick-slide slick-cloned "
                            data-slick-index={-1}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            <div className="-link-wrapper" style={{ paddingRight: "10px" }}>
                                <picture>
                                    <img
                                        className="img-fluid -slick-item -item-9"
                                        src="/build/web/igame-index-lobby-wm/img/bk8-vip-Banner-6.jpg"
                                        alt="First slide"
                                        width={1200}
                                        height={590}
                                        style={{ borderRadius: "0.5rem !important" }}
                                    />
                                </picture>
                            </div>
                        </div>
                    </Slider >

                </div>
            </div>

        );
    }
}
export default App;
