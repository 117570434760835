
import React, { Component } from 'react'; //different
//import './style.css';
import Login from './login';
import instance from "./axios-instance";
import Systems from "./SystemFunction";
import NavMenu from './navmenu';
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            linelink: "",
        }
    }
    async componentDidMount() {
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                this.setState({
                    linelink: datas[1].value,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }
    render() {
        return (
            <>
                <div className="-games-provider-wrapper pl-0 pr-0 mx-auto -menu-index-page-bg col-12">
                    {/**/}
                </div>
                <div className="-games-index-page col-12">
                    <div className="mb-2 text-white-v1">
                        <div className="d-none d-lg-block">
                        </div>
                        {/**/}
                        <div className="padding-router-view">
                            <div className="">
                                <div className="animated animatedFadeInUp fadeInUp m-3">
                                    <div className="container">
                                        <div className="row">
                                            <div className="pt-5 text-white-v1 text-center col col-sm-8 col-md-10 col-lg-8 col-xl-5 pl-0 pr-0 mx-auto my-auto">

                                                <br />
                                                <div className="x-modal-separator-container x-login-form animated animatedFadeInUp fadeInUp bg-signIn neon-auth-box">
                                                    {/**/}
                                                    <div className="-top">
                                                        <div data-animatable="fadeInModal" className="animated fadeInModal"
                                                        >
                                                            {/**/}
                                                            <div className="text-font-title-signin text-white-v1 mt-2 pt-2">
                                                                <img
                                                                    src="https://cdn.javisx.com/image/23dfb5f2-5f8d-4dfe-82b7-a7951f612836.png?v=1"
                                                                    className="logo-w-signIn-v113"
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="-img-container text-center">
                                                                <h6>เข้าระบบด้วยเบอร์โทรศัพท์</h6>
                                                            </div>
                                                            <Login />
                                                        </div>
                                                        {/**/}
                                                        <div className="mt-1 mb-1 w-100">
                                                            <h3 className="title-footer-wrap pt-2">
                                                                <b>Language / ภาษา </b>
                                                            </h3>
                                                            <ul className="logo-list-footer">
                                                                <li role="button">
                                                                    <img
                                                                        className="lang-img active-lang"
                                                                        src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/8e1626da-cc1f-451f-838f-54e5e1848f00/public"
                                                                        alt=""
                                                                    />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <div className="row m-0">
                                                        <div className="col-6 col-md-6">
                                                            <button className="btn -submit js-submit-accept-term v-login-register mt-lg-1 mt-0 w-100 p-2"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    window.location.href = '/register';
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={15}
                                                                    height={15}
                                                                    fill="currentColor"
                                                                    className="bi bi-person-fill"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                                                </svg>
                                                                <small>สมัครสมาชิก</small>
                                                            </button>
                                                        </div>
                                                        <div className="col-6 col-md-6">
                                                            <a href={this.state.linelink} className="btn -submit js-submit-accept-term v-login-contact btn-success mt-lg-1 mt-0 w-100 p-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={15}
                                                                    height={15}
                                                                    fill="currentColor"
                                                                    className="bi bi-telephone-fill"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                                                                    />
                                                                </svg>
                                                                <small>ติดต่อ</small>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>

        );
    }
}
export default App;
